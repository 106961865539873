import React, { Component } from 'react'
import { navigate } from 'gatsby'
import { getMarketoCookie } from '../utils/marketo'
import { readCookie, parseCookieToObject } from '../utils/cookie'
import Link from '../utils/link'
import { getSubscribedCookie, setSubscribedCookie } from "../utils/subscribe-popup";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class SubscribeForm extends Component {

  state = {
    firstname: '',
    lastname: '',
    email: '',
    is_website: true,
    subscribe_type: 'Residential', // Set initial state to an empty string
    form_name: this.props.disclaimer ? 'Subscribe-popup' : 'Subscribe',
    success: false,
  }

  constructor(props) {
    super(props)
    this.form = React.createRef()
  }

  componentDidMount() {
    let cookie = getMarketoCookie()
    this.setState({ cookie })
  }

  handleChange = e => {
    const { name, value, checked, type } = e.target;
    if (type === 'checkbox') {
      if (checked) {
        this.setState(prevState => ({
          [name]: prevState[name] ? `${prevState[name]},${value}` : value
        }));
      } else {
        this.setState(prevState => ({
          [name]: prevState[name].split(',').filter(item => item !== value).join(',')
        }));
      }
    } else {
      this.setState({ [name]: value });
    }
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.form.current.checkValidity()) return false
    if (this.state.subscribe_type === '') return false

    let message = `
      Subscribe Form Submission
    `

    let state = this.state
    for (let key in state) {
      if (typeof state[key] === 'object' && state[key] !== null && state[key].hasOwnProperty('value')) {
        state[key] = state[key].value;
      }
    }

    if (this.props.disclaimer) this.setState({ success: true })

    let utmFromCookie = readCookie('utmParameters');
    utmFromCookie = parseCookieToObject(utmFromCookie)

    return fetch(`https://kollosche.codedrips.com/wp-json/team/v1/enquiry`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        message,
        ...utmFromCookie,
        ...state
      })
    })
    .then(res => res.json())
    .then((res) => {
      if (res?.response?.status === 'success') {
        setSubscribedCookie();
        if (this.props.setPathFound) this.props.setPathFound(false)
        if ( this.props.disclaimer ){
          this.props.setSubscribePopup(false)
        } else {
          navigate('/news/thank-you/')
        }
      }
      if (res?.response?.errors) {
        this.setState({
          result: 'fail',
          msg: res.response.errors.map(err => err.detail.split('\n').map((el, i) => <div key={i}>{ el }</div>))
        }) 
      }
    })
    .catch(error => this.setState({result: 'fail', msg: error}))
  }

  render() {

    let props = {
      ref: this.form,
      name: 'subscribe',
      className: 'form',
      onSubmit: this.handleSubmit,
    }

    if ( this.state.success && this.props.disclaimer ) return (
      <div className='form form--success'>
        <p>Thank you for subscribing.</p>
      </div>
    )

    let { subscribe_type } = this.state

    return (

      <form {...props}>

        { this.props.disclaimer && 
          <div className='form__row form__row--checkboxes'>

            <label htmlFor='sales-appraisal'>Hear more about:</label>

            <div className='form__checkbox-group'>

              <div className='form__checkbox'>
                <input type='checkbox' id='residential-type' name="subscribe_type" value="Residential" checked={subscribe_type.includes('Residential')} onChange={this.handleChange} />
                <label htmlFor='residential-type'>Residential</label>
              </div>

              <div className='form__checkbox'>
                <input type='checkbox' id='commercial-type' name="subscribe_type" value="Commercial" onChange={this.handleChange} />
                <label htmlFor='commercial-type'>Commercial</label>
              </div>

            </div>

            { this.state.subscribe_type === '' && <p className='form__error'>Please make a selection.</p> }

          </div>
        }

        <div className='form__row'>

          <label htmlFor='first-name-field'> First Name </label>

          <input type='text' name='firstname' id='first-name-field' className='form__input' placeholder={this.props.disclaimer ? 'First Name' : ''} onChange={this.handleChange} required />

        </div>

        <div className='form__row'>

          <label htmlFor='first-name-field'> Last Name </label>

          <input type='text' name='lastname' id='first-name-field' className='form__input' placeholder={this.props.disclaimer ? 'Last Name' : ''} onChange={this.handleChange} required />

        </div>

        <div className='form__row'>

          <label htmlFor='email-field'> Email address </label>

          <input type='text' name='email' id='email-field' className='form__input' placeholder={this.props.disclaimer ? 'Email Address' : ''} onChange={this.handleChange} required />

        </div>

        <div className='form__row form__row--submit'>

          { this.props.disclaimer &&
            <p>By subscribing you consent to receive marketing communications from Kollosche as per our <Link to='/privacy-policy/' target='_blank' rel='noopener noreferrer'>Privacy Policy</Link></p>
          }
          
          <button type='submit' className='btn btn--border'>Subscribe</button>
        
        </div>

      </form>
    )
  }
}

export default SubscribeForm